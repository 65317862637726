import React, { useState } from 'react'; // Import useState from React
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './MainLayout';
import Home from './Home'; // Adjust the path as needed

import MainPage from './MainPage';

import RegisterPage from './RegisterPage';
import Login from './Login';
import LoginNew from './LoginNew';
import UserProfile from './UserProfile';
import EditUserProfile from './EditUserProfile';
import ListUsersCards from './ListUsersCards';
import SendTestEmail from './SendTestEmail';
import NewBanner from './NewBanner';

import ListArtists from './ListArtists';
import UploadAvatar from './UploadAvatar';

import ContactUs from './ContactUs';

import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';


import ArticleUpload from './ArticleUpload';
import UpdateMenuScript from './UpdateManuScript';
import UpdateMenuScriptForm from './UpdateManuScriptForm';
import UpdateAuthors from './UpdateAuthors';
import AuthorReviewFinalSubmit from './AuthorReviewFinalSubmit';
import AuthorDashboard from './AuthorDashboard';
import AdminDashboard from './AdminDashboard';

import MessageUser from './MessageUser';
import InboxMessages from './InboxMessages';
import ArticleStatus from './ArticleStatus';
import ArticlePublished from './ArticlePublished';
import ListArticlesPublish from './ListArticlesPublish';
import NRDArticlesPublish from './NRDArticlesPublish';
import ManuScriptSteps from './ManuScriptSteps';
import AdminLogin from './AdminLogin'
import AsignAuthorReview from './AsignAuthorReview';
import ReviewAuthorLogin from './ReviewAuthorLogin';
import MyReview from './MyReview';
import IssueCertificteReview from './IssueCertificteReview';
import UploadArticleAssign from './UploadArticleAssign';
import ReviewUpload from './ReviewUpload';
import ReviewCheckedArticle from './ReviewCheckedArticle';
import MyCertificateReview from './MyCertificateReview';
import AddArticleIssue from './AddArticleIssue';
import RegisterReviewer from './RegisterReviewer';

import RedirectCode from './RedirectCode';  





function App() {

  // Define your state for formData
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const isAuthenticated = sessionStorage.getItem('token');

  console.log('isAuthenticated:', isAuthenticated); // Add this line

  return (
    <BrowserRouter>
      <Routes>
        {/* Conditionally render the root component based on authentication status */}
        {isAuthenticated ? (

          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="userprofile" element={<UserProfile />} />
            <Route path="articleupload" element={<ArticleUpload />} />
            <Route path="updatemanuscript" element={<UpdateMenuScript />} />
            <Route path="updatemanuscriptform" element={<UpdateMenuScriptForm />} />
            <Route path="updateauthors" element={<UpdateAuthors />} />
            <Route path="authorreviewfinalsubmit" element={<AuthorReviewFinalSubmit />} />
            <Route path="articlestatus" element={<ArticleStatus />} />
            <Route path="articlepublished" element={<ArticlePublished />} />
            <Route path="messageuser" element={<MessageUser />} />
            <Route path="inboxmessages" element={<InboxMessages />} />
            <Route path="manuscriptsteps" element={<ManuScriptSteps />} />

            <Route path="authordashboard" element={<AuthorDashboard />} />

            <Route path="admindashboard" element={<AdminDashboard />} />
            <Route path="nrdarticlespublish" element={<NRDArticlesPublish />} />
            <Route path="assignauthorreview" element={<AsignAuthorReview />} />
            <Route path="reviewcheckedarticle" element={<ReviewCheckedArticle />} />
            <Route path="issuecertreview" element={<IssueCertificteReview />} />
            <Route path="mycertificatereview" element={<MyCertificateReview />} />
            <Route path="uploadarticleassign" element={<UploadArticleAssign />} />
            <Route path="myreview" element={<MyReview />} />
            <Route path="reviewupload" element={<ReviewUpload />} />
            <Route path="addarticleissue" element={<AddArticleIssue />} />

            <Route path="listarticlepublish" element={<ListArticlesPublish />} />

            <Route path="edituserprofile" element={<EditUserProfile />} />
            <Route path="listuserscards" element={<ListUsersCards />} />

            <Route path="listartists" element={<ListArtists />} />

            <Route path="uploadavatar" element={<UploadAvatar />} />
            <Route path="newbanner" element={<NewBanner />} />

            <Route path="contactus" element={<ContactUs />} />

  
          </Route>


        ) : (
          <Route path="/" element={<MainPage />} />
        )}

        {/* Other routes */}
        <Route path="mainpagehome" element={<MainPage />} />

        <Route path="redirectcode" element={<RedirectCode />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="registerreviewer" element={<RegisterReviewer />} />




        <Route path="login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="adminlogin" element={<AdminLogin />} />
        <Route path="reviewlogin" element={<ReviewAuthorLogin />} />




        <Route path="loginnew" element={<LoginNew />} />

        <Route path="sendtestemail" element={<SendTestEmail />} />




      </Routes>
    </BrowserRouter>
  );
}

export default App;
