import React, { useEffect, useState } from 'react';

const RedirectCode = () => {
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      console.log('Fetching redirection URL...');
      const response = await fetch('https://nrdruhs.online/redirectcode', {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched data:', data);

        if (data && data.data) {
          // Redirect to the URL from the backend
          console.log('Redirecting to:', data.data);
          window.location.replace(data.data);
        } else {
          setError('No valid redirection URL provided');
        }
      } else {
        setError('Failed to fetch redirection URL');
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return <div>Loading...</div>; // Display loading while fetching data
};

export default RedirectCode;
